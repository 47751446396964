<template>
    <div>
        <!-- 单文件上传 -->
        <!-- <el-upload
            class="upload-demo"
            ref="upload"
            name="files"
            :action="uploadUrl"
            :data="uploadDate"
            :on-progress="progress"
            :on-change="change"
            :headers="uploadHeader"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleImageSuccess"
            :on-error="uploadError"
            :file-list="fileList"
            :limit="max"
            list-type="picture-card"
        > -->
        <!-- 多文件上传 -->
        <el-upload
            ref="upload"
            multiple
            :limit="3"
            :action="uploadUrl"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :file-list="fileList"
            :http-request="uploadFile"
            :auto-upload="false">
            <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
            <el-button style="margin-left: 133px;" size="small" type="success" @click="sumit">上传到服务器
            </el-button>
        </el-upload>
        <el-button size="mini" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，且不超过500kb
        </div>
        </el-upload>
        <!-- <el-button @click="sumit()">上传</el-button>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt />
        </el-dialog> -->
    </div>
</template>
<script>
import setting from "@/utils/config/index.js";
import {uploadFiles} from "@/api/getOSSData"
export default {
    name: "uploadPic",
    props: ["msg", "max_num"],
    data() {
        return {
            // 文件上传数据（多文件合一）
            fileData: '',

            dialogImageUrl: "",

            dialogVisible: false,
            // 图片路径地址
            uploadUrl: setting.url.ossPath + "uploadFiles",
            // 测试数据
            cmag: this.msg,
            // 自动上传
            autoupload: false,
            // 文件上传头部
            uploadHeader: {
                content_type: "application/x-www-form-urlencoded",
            },
            // 文件上传参数
            uploadDate: {
                bucketName: "dh-order",
                fileName: "dh-order",
                fileNameList: ["test1", "test2", "test3"],
                fileaaa: this.fileList,
            },

            fileList: [],

            imgList: [],

            // 文件上传的最大数量
            max: this.max_num,
        };
    },
    methods: {
        // 手动上传文件
        uploadFile(file) {
            console.log("文件：",file)
            this.fileData.append("files", file.file);
        },
        handlePreview(file) {
            console.log(file);
        },
        change(file, fileList) {
            console.log("fileList", fileList);
            this.fileList = fileList;
            console.log("file", file);
        },
        progress(event, file, fileList) {
            console.log("event", event);
            console.log("file", file);
            console.log("fileList", fileList);
        },
        // 删除图片的函数
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        // 上传成功
        handleImageSuccess(res, file, fileList) {
            var url = "http://" + res.data.url + "";
            console.log("上传成功" + fileList.length);
            console.log("返回图片路径：" + url);
            console.log("图片列表", this.imgList);
            this.imgList.push(url);
            this.$message({
                message: "上传成功！",
                type: "success",
            });
            // 向父组件传值
            this.$emit("uploadResult", this.imgList);
        },
        // 上传失败
        uploadError(res) {
            console.log("上传失败" + res);
            this.$message({
                message: "上传失败！",
                type: "error",
            });
        },
        // 手动上传
        sumit() {
            let para = {
                bucketName:"dh-order",
                fileNameList:["test1","test2","test3"],
                files:this.fileData
            }
            uploadFiles(para).then((res)=>{
                this.$message({
                    message: "添加成功！",
                    type: "success",
                });
            });
        },
    },
};
</script>