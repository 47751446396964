<template>
    <div>
        <h1>这是一个测试页面</h1>
        <div class="demo-image__preview">
            <el-image
                style="width: 100px; height: 100px"
                :src="imgurl"
                :preview-src-list="imgList"
            >
            </el-image>
        </div>
        <uploadPic></uploadPic>
    </div>
</template>

<script>
import uploadPic from "@/components/uploadPic";
import testchildren from "@/components/testchildren";
import { testDemo } from "@/api/getBaseData";

export default {
    data() {
        return {
            imgList: [],
            imgurl: "",
        };
    },

    activated() {},
    mounted() {
        this.createEwm();
    },
    components: {
        uploadPic,
        // testchildren
    },
    methods: {
        // 上传成功函数
        uploadSuccess: function (uploadResult) {
            this.imgList = uploadResult;
            console.log("列表：", this.imgList);
        },
        createEwm: function () {
            let para = {};
            testDemo(para).then((result) => {
                let stream = result;

                const blob = new Blob([stream]);
                const url = window.URL.createObjectURL(blob);

                this.imgurl = url;
                this.imgList.push(this.imgurl);
            });
        },
    },
};
</script>

<style lang="scss">
.admin-index {
    .el-table thead {
        color: #21293b;
        font-size: 0.8rem;
    }
    .el-table th,
    .el-table thead {
        background-color: #e9ecee;
        height: 3rem;
    }
    .el-table {
        font-size: 0.8rem;
    }
}
</style>
