import request from '@/plugin/axios/oss'


// 文件上传
export function uploadFile(data){
    return request({
        url: '/oss/uploadFile',
        method: 'POST',
        headers:{'Content-Type':'multipart/form-data'},
        data
    });
}

// 多文件上传
export function uploadFiles(data){
    return request({
        url: 'http://localhost:7011/oss/uploadFiles',
        method: 'POST',
        headers:{'Content-Type':'multipart/form-data'},
        data
    });
}



//删除文件
export function deleteFile(data){
    return request({
        url: '/oss/deleteFile',
        method: 'POST',
        headers:{'Content-Type':'multipart/form-data'},
        data
    });
}