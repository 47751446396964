<template>
    <div>
        <h1>我是一个测试组件</h1>
        <p>这是从父组件传来的数据：{{cmag}}</p>
    </div>
</template>
<script>
export default {
    name: "testchildren",
    props: ["msg"],
    data() {
        return {
            cmag: this.msg,
            obj: {
                name: "张三",
                age: 12,
                sex: "男",
            },
        };
    },
    mounted() {
        // 参数一，实例[父组件]定义的方法
        // 参数二，可选的，子组件向父组件传递的数据，可以是多个，
        this.$emit("func", this.obj);
    },
    methods: {},
};
</script>